import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import {
  Container,
  HeroBackground,
  HeroSubtitle,
  HeroTitle,
  InternationalDealersList,
} from "./styles";
import InfiniteScroll from "react-infinite-scroller";
import { getPaginatedContent } from "../../utils/pagination";
import InternationalDistributor from "./InternationalDistributor";

const ITEMS_PER_PAGE = 10;
const DEBOUNCED_TIME_MS = 200;

const InternationalLocationsView = ({ data }) => {
  const [distributors, setDistributors] = useState([]);
  const [paginationPage, setPaginationPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  const lastPage = Math.ceil(data.length / ITEMS_PER_PAGE);

  const getDistributors = useCallback(
    ({ startPage = 0 }) =>
      getPaginatedContent({
        data,
        currentPageIndex: startPage,
        pageSize: ITEMS_PER_PAGE,
        totalItems: data.length,
        callback: (next) => {
          setDistributors((prev) => [...prev, ...next]);
          setHasMore(startPage < lastPage);
        },
      }),
    [data, lastPage]
  );

  const getDistributorsDebounced = debounce(getDistributors, DEBOUNCED_TIME_MS);

  useEffect(() => {
    getDistributorsDebounced({ startPage: paginationPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationPage]);

  const loadMore = () => {
    setPaginationPage((prev) => {
      return prev + 1 <= lastPage ? prev + 1 : prev;
    });
  };

  return (
    <Container>
      <HeroBackground>
        <HeroTitle>Our International Distributors</HeroTitle>
        <HeroSubtitle>
          See below to find a Certified Reseller near you. Reaching out to one
          of our certified professionals is the first step in creating your
          Savant smart home experience.
        </HeroSubtitle>
      </HeroBackground>
      <InternationalDealersList>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          hasMore={hasMore}
          initialLoad={false}
          threshold={DEBOUNCED_TIME_MS}
        >
          {distributors.map((distributor, index) => (
            <InternationalDistributor
              key={`${distributor.uid}-${index}`}
              isEven={index % 2 === 0}
              {...distributor}
            />
          ))}
        </InfiniteScroll>
      </InternationalDealersList>
    </Container>
  );
};

export default InternationalLocationsView;
