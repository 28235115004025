import styled from "styled-components";
import { media } from "../../../components/Theme/default-theme";

export const Container = styled.div`
  background: ${({ isEven }) => (isEven ? "#F2F2F2" : "#fff")};
  height: 250px;

  @media (max-width: 768px) {
    padding: 0 30px;
  }
`;

export const ContainerInner = styled.div`
  margin: 0 auto;
  max-width: 880px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  color: #696057;
  font-style: normal;
  font-family: Gotham, serif;

  ${media.mobile} {
    max-width: 800px;
  }
`;

export const CountriesNames = styled.div`
  margin: 0 0 5px;
  font-weight: 500;
  font-size: 17px;
  line-height: 30px;
  text-transform: uppercase;
`;

export const CompanyName = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const AddressOffice = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
`;

export const Phone = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
`;

export const Email = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
`;
