import React from "react";
import {
  ContainerInner,
  Container,
  CountriesNames,
  CompanyName,
  AddressOffice,
  Phone,
  Email,
} from "./styles";

const InternationalDistributor = ({
  title,
  subtitle,
  paragraph,
  email,
  phone,
  isEven,
}) => {
  return (
    <Container isEven={isEven}>
      <ContainerInner>
        <CountriesNames>{title}</CountriesNames>
        <CompanyName>{subtitle}</CompanyName>
        {paragraph && <AddressOffice>{paragraph}</AddressOffice>}
        {phone && (
          <div>
            <Phone href={`tel:${phone}`}>{phone}</Phone>
          </div>
        )}
        {email && (
          <div>
            <Email href={`mailto:${email}`}>{email}</Email>
          </div>
        )}
      </ContainerInner>
    </Container>
  );
};

export default InternationalDistributor;
